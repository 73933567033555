import logo from './logo.svg';
import './App.css';
import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home.js';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import $ from 'jquery';


function App() {

  return (
      <Router>
      <div className="row">
      <div className="content">

        <div className="App">
          <Routes>

            <Route path="/" element={<Home/>}/>
            <Route path="*" element={<Home/>}/>

          </Routes>

        </div>
        </div>
        </div>
      </Router>
  );

}

export default App;
