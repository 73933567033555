import React, { Component, useState } from 'react';
import bm_logo from '../src/bm_logo.png';
import { v4 as uuidv4 } from 'uuid';

const Home = () => {

  const [url, setUrl] = useState("");
  const [inputFields, setInputFields] = useState([]);
  const [btncopytext, setBtncopytext] = useState("Zkopírovat");

  function handleSubmit(e) {
      e.preventDefault()
      const {product, code } = e.target.elements
      const newUrl = product.value+"?utm_source=social&utm_medium=influencer&utm_campaign="+code.value;
      console.log({product: product.value, code: code.value, url: newUrl });
      setInputFields([{ id: uuidv4(),  Url: newUrl}]);
  }

  const handleChange = (e) => {
    e.preventDefault(); // prevent the default action
    setUrl(e.target.value); // set name to e.target.value (event)
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map(i => {
      if(id === i.id) {
        i[event.target.name] = event.target.value
      }
      return i;
    })

    setInputFields(newInputFields);
  }

  const copyToClipboard = (content: any) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

    return (
        <div className="home">
            <div className="header">
              <img src={bm_logo} width="300" height="300" />

            </div>
            <div className="generator container">
                <h2 id="generator" className="generator title">Generátor odkazu</h2>

                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">URL produktu</label>
                    <input type="text" required="required" className="form-control" id="product" aria-describedby="productHelp" placeholder="https://www.brainmarket.cz/brainmax-pure/brainmax-pure-maple-apple-cinnamon-granola--granola-javorovy-sirup--jablko--skorice--400g/"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="coupon">Kód influencera</label>
                    <input type="text" required="required" className="form-control" id="code" placeholder="Zadejte kód"/>
                  </div>

                  <button type="submit" className="btn btn-primary gen-btn" onClick={() => {setBtncopytext("Zkopírovat")}}>Vygeneruj odkaz</button>

                  { inputFields.map((inputField, index) => (
                    <div key={inputField.id}>
                    <label htmlFor="coupon">Vygenerovaný odkaz</label>
                      <input type="text" className="form-control"
                        name="newUrl"
                        label="New Url"
                        variant="filled"
                        value={inputField.Url}
                        onChange={event => handleChangeInput(inputField.id, event)}
                        readOnly
                      />
                      <button className="btn btn-primary gen-btn" onClick={() => { copyToClipboard(inputField.Url); setBtncopytext("Zkopírováno !");}}>{btncopytext}</button>
                    </div>
                  )) }
                </form>



            </div>
        </div>
    );
}

export default Home;
